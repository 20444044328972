import React from "react";
import { Button } from "./Button";
import "../styles/HeroSection.css";
import { Container, Row, Col } from "react-bootstrap";

function HeroSection({ showGallery }) {
  return (
    <div className="hero-container home">
      <Container>
        <Row>
          <Col xsm={12} lg={6}>
            <h1 className="show-on-scroll">
              The <i>ultimate</i> solution for <br/><b>home inspections</b>
            </h1>
            <p className="show-on-scroll">
              Designed by home inspectors for home inspectors, Home Inspectors
              Toolbox helps solve your home inspection reporting needs. Simple
              and easy to use.
            </p>
            <div className="hero-btns">
              <h3>Sign up now to get your <u>14-Day FREE trial</u>!</h3>
              <Button
                className="btns"
                buttonStyle="btn--primary"
                buttonSize="btn--large"
                onClick={() => window.open('https://app.homeinspectorstoolbox.com/register', '_blank') }
              >
                Sign Up Now
              </Button>
            </div>
            <br />
            <br />
            <br />
          </Col>
          <Col xsm={12} lg={6}>
            <img
              src={require("../assets/images/web-banner-img.png")}
              alt="home-inspectors-toolbox-preview-on-laptop-and-mobile"
              className="img-fluid"
              onClick={showGallery}
              style={{ cursor: "pointer" }}
            />
            <Row style={{ marginTop: "30px" }}>
              <Col xsm={12} lg={6} onClick={() => window.open('https://play.google.com/store/apps/details?id=com.home.inspectors.toolbox', '_blank')} style={{cursor: 'pointer'}}>
                  <img
                    src="/images/android.webp"
                    style={{ maxHeight: "50px", objectFit: "contain" }}
                    alt="home-inspectors-toolbox-preview-on-laptop-and-mobile"
                    className="img-fluid center"
                  />
              </Col>
              <Col xsm={12} lg={6} className="mt-3 mt-md-0" onClick={() => window.open('https://apps.apple.com/us/app/home-inspectors-toolbox/id6450447573', '_blank')} style={{cursor: 'pointer'}}>
                  <img
                    src="/images/ios.webp"
                    style={{ maxHeight: "50px", objectFit: "contain" }}
                    alt="home-inspectors-toolbox-preview-on-laptop-and-mobile"
                    className="img-fluid center"
                  />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default HeroSection;
