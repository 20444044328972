import { Col, Row, Image } from "react-bootstrap";
import { features } from "../data/features";

import "../styles/HeroFeatures.css";

export const HeroFeatures = () => {
  return (
    <div className="hero-features">
      <Row>
        {features.map((feature, index) => {
          return (
            <Col className="feature-item mb-5" key={index} md={6} lg={4}>
              <Row>
                <Col xs={3}>
                  <Image fluid src={feature.icon.default} alt="" />
                </Col>
                <Col>
                  <div className="d-flex flex-column">
                    <h3 className="feature-item__name">{feature.name}</h3>
                    <p className="feature-item__description m-0">
                      {feature.description}
                    </p>
                  </div>
                </Col>
              </Row>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default HeroFeatures;
