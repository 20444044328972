import { reportIcons } from "../assets/images/reports";

export const reports = [
  {
    name: "Four Point Inspection",
    icon: reportIcons[0],
  },
  {
    name: "Wind Mitigation Inspection",
    icon: reportIcons[1],
  },
  {
    name: "Roof Certification Inspection",
    icon: reportIcons[2],
  },
  {
    name: "Commercial Roof Certification Inspection",
    icon: reportIcons[3],
  },
  {
    name: "Wind Mitigation II & III Inspection (Commercial)",
    icon: reportIcons[4],
  },
  {
    name: "Wood Destroying Organisms Inspection (WDO)",
    icon: reportIcons[5],
  },
  {
    name: "Veterans Administration Wood Destroying Organisms Inspection (VA WDO)",
    icon: reportIcons[6],
  },
  {
    name: "Radon Inspection",
    icon: reportIcons[7],
  },
];
