import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "./Button";
import "../styles/Navbar.css";
import moment from "moment-timezone";
import { socialIcons } from "../assets/icons";

function Navbar({ onJump }) {
  const [isOpen, setIsOpen] = useState(false);
  const [button, setButton] = useState(true);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener("resize", showButton);

  return (
    <>
      <nav className="navbar">
        <div className="navbar-container">
          <Link to="/" onClick={() => setIsOpen(false)} className="navbar-logo">
            <img
              src="/images/home-inspectors-logo-white.png"
              alt="home-inspectors-toolbox-navbar-logo"
            />
          </Link>
          <div className="menu-icon" onClick={() => setIsOpen(!isOpen)}>
            <i className={isOpen ? "fas fa-times" : "fas fa-bars"} />
          </div>
          <ul className={isOpen ? "nav-menu active" : "nav-menu"}>
            {!button ? (
              <>
                <li className="nav-item nav-item--link mt-4">
                  <Link
                    to="/"
                    className="nav-links-mobile nav-links-mobile--link"
                    onClick={() => {
                      setIsOpen(false);
                      onJump("hit-pricing");
                    }}
                  >
                    Pricing
                  </Link>
                </li>
                <li className="nav-item nav-item--link">
                  <Link
                    to="/"
                    className="nav-links-mobile nav-links-mobile--link"
                    onClick={() => {
                      setIsOpen(false);
                      onJump("hit-features");
                    }}
                  >
                    Features
                  </Link>
                </li>
                <li className="nav-item nav-item--link">
                  <Link
                    to="/terms-and-conditions"
                    className="nav-links-mobile nav-links-mobile--link"
                    onClick={() => setIsOpen(false)}
                  >
                    Terms and Conditions
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-links-mobile"
                    onClick={() =>
                      window.open(
                        "https://app.homeinspectorstoolbox.com/register",
                        "_blank"
                      )
                    }
                  >
                    Sign Up
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-links-mobile"
                    onClick={() =>
                      window.open(
                        "https://app.homeinspectorstoolbox.com/login",
                        "_blank"
                      )
                    }
                  >
                    Login
                  </Link>
                </li>
                <li className="nav-item" style={{ marginTop: 40 }}>
                  <p className="text-white italic text-small">
                    © Home Inspectors Toolbox{" "}
                    {moment(new Date()).tz("America/New_York").format("YYYY")}
                  </p>
                </li>
                <div>
                  <h1 className="text-white" style={{ fontWeight: "normal" }}>
                    Socials
                  </h1>
                  <div className="d-flex justify-content-center mt-4">
                    <Link
                      to="https://www.facebook.com/homeinspectorstoolbox.fl"
                      target="_blank"
                      className="hit-link--social me-4"
                    >
                      <img
                        className="w-100"
                        src={socialIcons.facebook}
                        alt=""
                      />
                    </Link>
                    <Link
                      to="https://www.instagram.com/homeinspectorstoolbox/
                        "
                      target="_blank"
                      className="hit-link--social me-4"
                    >
                      <img
                        className="w-100"
                        src={socialIcons.instagram}
                        alt=""
                      />
                    </Link>
                    <Link
                      to="https://www.youtube.com/@homeinspectorstoolbox"
                      target="_blank"
                      className="hit-link--social me-4"
                    >
                      <img className="w-100" src={socialIcons.youtube} alt="" />
                    </Link>
                    <Link
                      to="https://www.linkedin.com/company/home-inspectors-toolbox"
                      target="_blank"
                      className="hit-link--social"
                    >
                      <img
                        className="w-100"
                        src={socialIcons.linkedin}
                        alt=""
                      />
                    </Link>
                  </div>
                </div>
              </>
            ) : (
              <>
                <br />
                <Button
                  buttonStyle="btn--outline"
                  onClick={() =>
                    window.open(
                      "https://app.homeinspectorstoolbox.com/register",
                      "_blank"
                    )
                  }
                >
                  Sign Up
                </Button>
                <Button
                  buttonStyle="btn--outline"
                  onClick={() =>
                    window.open(
                      "https://app.homeinspectorstoolbox.com/login",
                      "_blank"
                    )
                  }
                >
                  Login
                </Button>
              </>
            )}
          </ul>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
