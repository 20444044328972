/* export const featureImages = {
  sixInspectionForms: require("./six-inspection-forms.png"),
  quickAndEasy: require("./quick-and-easy.png"),
  multipleInspectors: require("./multiple-inspectors.png"),
  automaticReportGeneration: require("./automatic-report-generation.png"),
  automaticInvoiceGeneration: require("./automatic-invoice-generation.png"),
  accountSynchronization: require("./account-synchronization.png"),
  savedSearchableRecords: require("./saved-and-searchable-records.png"),
  safeAndSecure: require("./safe-and-secure.png"),
}; */

export const featureImages = [
  require("./six-inspection-forms.png"),
  require("./quick-and-easy.png"),
  require("./multiple-inspectors.png"),
  require("./automatic-report-generation.png"),
  require("./automatic-invoice-generation.png"),
  require("./account-synchronization.png"),
  require("./saved-and-searchable-records.png"),
  require("./safe-and-secure.png"),
];

export const featureIcons = [
  require("./icons/6+-inspection-forms.svg"),
  require("./icons/quick-and-easy.svg"),
  require("./icons/multiple-inspectors.svg"),
  require("./icons/automatic-report-generation.svg"),
  require("./icons/automatic-invoice-generation.svg"),
  require("./icons/account-synchronization.svg"),
  require("./icons/saved-searchable-records.svg"),
  require("./icons/safe-and-secure.svg"),
];

export const heroCarouselImages = [
  require("./account-synchronization.png"),
  require("./six-inspection-forms.png"),
  require("./saved-and-searchable-records.png"),
  require("./quick-and-easy.png"),
  require("./automatic-report-generation.png"),
];
