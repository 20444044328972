import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";

function TermsAndConditions() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="container terms-and-conditions-section">
        <Container>
          <Row>
            <Col>
              <h1 className="text-center">Terms and Conditions</h1>
              <p className='hit-p-terms-text justify primary'>
                  These terms and conditions ("Agreement") set forth the general terms and conditions of your use of Home Inspectors Toolbox -  “homeinspectorstoolbox.com” website ("Website"), "Home Inspectors Toolbox" mobile application ("Mobile Application") and any of its related products and services (collectively, "Services"). 
              </p>
              <p className='hit-p-terms-text justify primary'>
                  This Agreement is legally binding between you ("User", “subscriber”, "you" or "your") and Home Inspectors Toolbox, Inc. ("Home Inspectors Toolbox", "we", "us" or "our"). 
              </p>
              <p className='hit-p-terms-text justify primary'>
                  By accessing and using the Services, you acknowledge that you have read, understood, and agree to be bound by the terms of this Agreement. If you are entering into this Agreement on behalf of a business or other legal entity, you represent that you have the authority to bind such entity to this Agreement, in which case the terms "User", “subscriber” "you" or "your" shall refer to such entity. If you do not have such authority, or if you do not agree with the terms of this Agreement, you must not accept this Agreement and may not access and use the Services. You acknowledge that this Agreement is a contract between you and Home Inspectors Toolbox, Inc., even though it is electronic and is not physically signed by you, and it governs your use of the Services.
              </p>
              <hr/>

              <h5 className='hit-h5 center secondary'>Account Registration and Membership</h5>
              <p className='hit-p-terms-text justify primary'>
                  Users must be at least 18 years of age to use the Services. By using the Services and by agreeing to this Agreement you warrant and represent that you are at least 18 years of age. If you create an account for these Services, you are responsible for maintaining the accuracy of your account and you are fully responsible for all activities that occur under the account and any other actions taken in connection with it. 
              </p>
              <p className='hit-p-terms-text justify primary'>
                  We may monitor and review new accounts before you may sign in and at the start of using the Services. Providing false information of any kind may result in the termination of your account. 
              </p>
              <p className='hit-p-terms-text justify primary'>
                  You must immediately notify us of any unauthorized uses of your account or any other breaches of security. We will not be liable for any acts or omissions by you, including any damages of any kind incurred as a result of such acts or omissions. We may suspend, disable, or delete your account (or any part thereof) if we determine that you have violated any provision of this Agreement or that your conduct or content would tend to damage our reputation and goodwill. If we delete your account for the foregoing reasons, you may not re-register for our Services. We may block your email address and Internet protocol address to prevent further registration.
              </p>
              <p className='hit-p-terms-text justify primary'>
                  Upon sign-up, registered users of Home Inspectors Toolbox will create a company profile that includes their name, company name, and additional details including license type, license number, e-signature, email address and e-initials. This information is captured to auto-populate the customized reports, invoices and customer communications created by Home Inspectors Toolbox for its users. Registered users can only change their license type, license number, e-signature, and e-initials once a year. Users are encouraged to ensure this information is entered correctly at sign-up to avoid any future issues. 
              </p>
              
              <h5 className='hit-h5 center secondary'>Multiple Account Users</h5>
              <p className='hit-p-terms-text justify primary'>
                  Home Inspectors Toolbox users may add (purchase) up to five (5) additional home inspector accounts to their subscriptions under the same company at a reduced monthly cost. 
              </p>
              
              <h5 className='hit-h5 center secondary'>Billing and Payments</h5>
              <p className='hit-p-terms-text justify primary'>
                  Upon registration of Home Inspectors Toolbox, you will be required to provide your debit/credit card information via STRIPE payment gateway to activate your membership subscription. 
              </p>
              <p className='hit-p-terms-text justify primary'>
                  Membership will be automatically renewed and membership fees automatically deducted/applied to the debit/credit card provided upon registration. This process is not handled by Home Inspectors Toolbox, but by STRIPE payment gateway.
              </p>
              <p className='hit-p-terms-text justify primary'>
                  Home Inspectors Toolbox does not save any member payment or bank account information in its database or server(s).
              </p>
              <p className='hit-p-terms-text justify primary'>
                  Members shall pay all fees and charges required for to keep their account in good standing in accordance with the fees, charges, and billing terms in effect at the time a fee or charge is due and payable. If, in our judgment, your purchase constitutes a high-risk transaction, we will require you to provide us with a copy of your valid government-issued photo identification, and possibly a copy of a recent bank statement for the credit or debit card used for the purchase. 
              </p>
              <p className='hit-p-terms-text justify primary'>
                  We reserve the right to change products and product pricing at any time. We also reserve the right to refuse any request for service you place with us. We may, at our sole discretion, limit or cancel quantities purchased per person, per company, per household, or per order. These restrictions may include account registration/sign-up by or under the same customer account, the same credit card, and/or that use the same billing and/or shipping address. In the event that we make a change to or cancel a registration sign-up request, we may attempt to notify you by contacting the e-mail and/or billing address/phone number provided at the time sign-up was attempted.
              </p>
              

              <h5 className='hit-h5 center secondary'>Cancellation Terms and Conditions</h5>
              <p className='hit-p-terms-text justify primary'>
                  Registered users of Home Inspectors Toolbox may cancel their subscription at any time without penalty. Users will be billed for the full month of final usage regardless of date of cancellation. Refunds and credits will not be granted nor applicable.
              </p>
              <p className='hit-p-terms-text justify primary'>
                  Upon cancellation of your Home Inspectors Toolbox subscription, users will have seven (7) days from the date of cancellation to download and save their client list.
              </p>
              <p className='hit-p-terms-text justify primary'>
                  After (seven) 7 days of receipt of notice of cancellation by Home Inspectors Toolbox, user client list will be automatically transferred to us.
              </p>
              <p className='hit-p-terms-text justify primary'>
                  To retrieve a user’s client list after the seven (7) cancellation window, an administrative retrieval fee will be charged to the user to receive the client list. 
              </p>
              <p className='hit-p-terms-text justify primary'>
                  Home Inspectors Toolbox reserves the right to cancel a user’s account at any time for abuse of use, user infringement of the terms and conditions outlined here, and/or delinquency of payment that exceeds seven (7) days.
              </p>

              <h5 className='hit-h5 center secondary'>Accuracy of Information</h5>
              <p className='hit-p-terms-text justify primary'>
                  Occasionally there may be information contained within the Services provided that contain typographical errors, inaccuracies, or omissions. We reserve the right to correct any errors, inaccuracies, or omissions, and to change or update information or cancel Services if any information within the Services provided is inaccurate at any time without prior notice (including after you have submitted your registration sign-up application). We undertake no obligation to update, amend or clarify information related to our Services including, without limitation, pricing information, except as required by law. No specified update or refresh date applied on the Services should be taken to indicate that all information on the Service or Services has been modified or updated.
              </p>
              
              <h5 className='hit-h5 center secondary'>Advertisements</h5>
              <p className='hit-p-terms-text justify primary'>
                  During your use of the Services, you may enter into correspondence with or participate in promotions of advertisers or sponsors showing their goods or services through the Services. Any such activity, and any terms, conditions, warranties, or representations associated with such activity, is solely between you and the applicable third party. We shall have no liability, obligation, or responsibility for any such correspondence, purchase, or promotion between you and any such third party.
              </p>
              
              <h5 className='hit-h5 center secondary'>Links to Other Resources</h5>
              <p className='hit-p-terms-text justify primary'>
                  Although the Services may link to other resources (such as websites, mobile applications, etc.), we are not, directly, or indirectly, implying any approval, association, sponsorship, endorsement, or affiliation with any linked resource, unless specifically stated herein. Some of the links on the Services may be "affiliate links". This means if you click on the link and purchase an item, Home Inspectors Toolbox, Inc. will receive an affiliate commission. We are not responsible for examining or evaluating such affiliate services, and we do not warrant the offerings of any businesses or individuals or the content of their resources. We do not assume any responsibility or liability for the actions, products, services, and content of any other third parties. You should carefully review the legal statements and other conditions of use of any resource which you access through a link on the Services. Home Inspectors Toolbox users are linking to any other off-site resources at your own risk.
              </p>
              
              <h5 className='hit-h5 center secondary'>Prohibited Uses</h5>
              <p className='hit-p-terms-text justify primary'>
                  In addition to other terms as set forth in the Agreement, you are prohibited from using the Services or Content: (a) for any unlawful purpose; (b) to solicit others to perform or participate in any unlawful acts; (c) to violate any international, federal, provincial or state regulations, rules, laws, or local ordinances; (d) to infringe upon or violate our intellectual property rights or the intellectual property rights of others; (e) to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability; (f) to submit false or misleading information; (g) to upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Services, third party products and services, or the Internet; (h) to spam, phish, pharm, pretext, spider, crawl, or scrape; (i) for any obscene or immoral purpose; or (j) to interfere with or circumvent the security features of the Services, third party products and services, or the Internet. We reserve the right to terminate your use of the Services for violating any of the prohibited uses.
              </p>
              
              <h5 className='hit-h5 center secondary'>Intellectual Property Rights</h5>
              <p className='hit-p-terms-text justify primary'>
                  "Intellectual Property Rights" means all present and future rights conferred by statute, common law or equity in or in relation to any copyright and related rights, trademarks, designs, patents, inventions, goodwill and the right to sue for passing off, rights to inventions, rights to use, and all other intellectual property rights, in each case whether registered or unregistered and including all applications and rights to apply for and be granted, rights to claim priority from, such rights and all similar or equivalent rights or forms of protection and any other results of intellectual activity which subsist or will subsist now or in the future in any part of the world. This Agreement does not transfer to you any intellectual property owned by Home Inspectors Toolbox, Inc. or third parties, and all rights, titles, and interests in and to such property will remain (as between the parties) solely with Home Inspectors Toolbox, Inc. All trademarks, service marks, graphics and logos used in connection with the Services, are trademarks or registered trademarks of Home Inspectors Toolbox, Inc. or its licensors. Other trademarks, service marks, graphics and logos used in connection with the Services may be the trademarks of other third parties. Your use of the Services grants you no right or license to reproduce or otherwise use any of Home Inspectors Toolbox, Inc. or third party trademarks.
              </p>
              
              <h5 className='hit-h5 center secondary'>Disclaimer of Warranty</h5>
              <p className='hit-p-terms-text justify primary'>
                  You agree that such Service is provided on an "as is" and "as available" basis and that your use of the Services is solely at your own risk. We expressly disclaim all warranties of any kind, whether express or implied, including but not limited to the implied warranties of merchantability, fitness for a particular purpose and non-infringement. We make no warranty that the Services will meet your requirements, or that the Service will be uninterrupted, timely, secure, or error-free; nor do we make any warranty as to the results that may be obtained from the use of the Service or as to the accuracy or reliability of any information obtained through the Service or that defects in the Service will be corrected. You understand and agree that any material and/or data downloaded or otherwise obtained through the use of Service is done at your own discretion and risk and that you will be solely responsible for any damage or loss of data that results from the download of such material and/or data. We make no warranty regarding any goods or services purchased or obtained through the Service or any transactions entered into through the Service unless stated otherwise. No advice or information, whether oral or written, obtained by you from us or through the Service shall create any warranty not expressly made herein.
              </p>
              
              <h5 className='hit-h5 center secondary'>Limitation of Liability</h5>
              <p className='hit-p-terms-text justify primary'>
                  To the fullest extent permitted by applicable law, in no event will Home Inspectors Toolbox, Inc., its affiliates, directors, officers, employees, agents, suppliers or licensors be liable to any person for any indirect, incidental, special, punitive, cover or consequential damages (including, without limitation, damages for lost profits, revenue, sales, goodwill, use of content, impact on business, business interruption, loss of anticipated savings, loss of business opportunity) however caused, under any theory of liability, including, without limitation, contract, tort, warranty, breach of statutory duty, negligence or otherwise, even if the liable party has been advised as to the possibility of such damages or could have foreseen such damages. To the maximum extent permitted by applicable law, the aggregate liability of Home Inspectors Toolbox, Inc. and its affiliates, officers, employees, agents, suppliers and licensors relating to the services will be limited to an amount greater of one dollar or any amounts actually paid in cash by you to Home Inspectors Toolbox, Inc. for the prior one month period prior to the first event or occurrence giving rise to such liability. The limitations and exclusions also apply if this remedy does not fully compensate you for any losses or fails of its essential purpose. Further:
              </p>
              <p className='hit-p-terms-text justify primary'>
                  Home Inspectors Toolbox is not liable for any inspection reports created by registered inspectors/users.
              </p>
              <p className='hit-p-terms-text justify primary'>
                  Home Inspectors Toolbox is not liable for any inspection pricing billed to customers/clients of its inspector users.
              </p>
              <p className='hit-p-terms-text justify primary'>
                  Home Inspectors Toolbox is not liable for the deletion of an inspection report by the inspector/user.
              </p>
              <p className='hit-p-terms-text justify primary'>
                  Home Inspectors Toolbox is not liable for home inspection scheduling conflicts that may arise using the application’s inspection scheduling feature. To avoid scheduling conflicts, home inspectors are encouraged to review their schedule before accepting/confirming customer inspection request.
              </p>
              <p className='hit-p-terms-text justify primary'>
                  Home Inspectors Toolbox is not liable for any emails sent – nor the information contained in such emails - by its registered users to their clients/customers.
              </p>
              <p className='hit-p-terms-text justify primary'>
                  Home Inspectors Toolbox is not liable for the accidental deletion of client information by the inspector.
              </p>

              <h5 className='hit-h5 center secondary'>Connectivity</h5>
              <p className='hit-p-terms-text justify primary'>
                  By accepting these terms and conditions you agree to the Home Inspectors Toolbox mobile connectivity options. The mobile app is operable via Wifi or your via the users mobile service provider. In the event of a service disruption, Home Inspectors Toolbox will allow you to capture the information you need and hold it on their server for a limited time until your service is restored, at which time the information entered will be uploaded to your account/profile.
              </p>

              <h5 className='hit-h5 center secondary'>Indemnification</h5>
              <p className='hit-p-terms-text justify primary'>
                  You agree to indemnify and hold Home Inspectors Toolbox, Inc. and its affiliates, directors, officers, employees, agents, suppliers and licensors harmless from and against any liabilities, losses, damages or costs, including reasonable attorneys' fees, incurred in connection with or arising from any third party allegations, claims, actions, disputes, or demands asserted against any of them as a result of or relating to your Content, your use of the Services or any willful misconduct on your part. 
              </p>
              <p className='hit-p-terms-text justify primary'>
                  Home Inspectors Toolbox is not responsible for the content contained in the forms and reports home inspection companies and home inspectors provide to homeowners and insurance companies via our web/mobile app. The information entered into the insurance inspection forms and reports we provide is the sole responsibility of the home inspection companies and inspectors who subscribe to use our service.
              </p>

              <h5 className='hit-h5 center secondary'>Severability</h5>
              <p className='hit-p-terms-text justify primary'>
                  All rights and restrictions contained in this Agreement may be exercised and shall be applicable and binding only to the extent that they do not violate any applicable laws and are intended to be limited to the extent necessary so that they will not render this Agreement illegal, invalid, or unenforceable. If any provision or portion of any provision of this Agreement shall be held to be illegal, invalid, or unenforceable by a court of competent jurisdiction, it is the intention of the parties that the remaining provisions or portions thereof shall constitute their agreement with respect to the subject matter hereof, and all such remaining provisions or portions thereof shall remain in full force and effect.
              </p>
              
              <h5 className='hit-h5 center secondary'>Dispute Resolution</h5>
              <p className='hit-p-terms-text justify primary'>
                  The formation, interpretation, and performance of this Agreement and any disputes arising out of it shall be governed by the substantive and procedural laws of Florida, United States without regard to its rules on conflicts or choice of law and, to the extent applicable, the laws of the United States. The exclusive jurisdiction and venue for actions related to the subject matter hereof shall be the courts located in Florida, United States, and you hereby submit to the personal jurisdiction of such courts. You hereby waive any right to a jury trial in any proceeding arising out of or related to this Agreement. The United Nations Convention on Contracts for the International Sale of Goods does not apply to this Agreement.
              </p>
              
              <h5 className='hit-h5 center secondary'>Assignment</h5>
              <p className='hit-p-terms-text justify primary'>
                  You may not assign, resell, sub-license or otherwise transfer or delegate any of your rights or obligations hereunder, in whole or in part, without our prior written consent, which consent shall be at our own sole discretion and without obligation; any such assignment or transfer shall be null and void. We are free to assign any of its rights or obligations hereunder, in whole or in part, to any third party as part of the sale of all or substantially all of its assets or stock or as part of a merger.
              </p>
              
              <h5 className='hit-h5 center secondary'>Changes and Amendments</h5>
              <p className='hit-p-terms-text justify primary'>
                  We reserve the right to modify this Agreement or its terms relating to the Services at any time, effective upon posting of an updated version of this Agreement on the Services. When we do, we will post a notification within the Services. Continued use of the Services after any such changes shall constitute your consent to such changes.
              </p>
              
              <h5 className='hit-h5 center secondary'>Acceptance of These Terms</h5>
              <p className='hit-p-terms-text justify primary'>
                  You acknowledge that you have read this Agreement and agree to all its terms and conditions. By accessing and using the Services you agree to be bound by this Agreement. If you do not agree to abide by the terms of this Agreement, you are not authorized to access or use the Services.
              </p>

              <h5 className='hit-h5 center secondary'>Contacting Us</h5>
              <p className='hit-p-terms-text justify primary'>
                  If you would like to contact us to understand more about this Agreement or wish to contact us concerning any matter relating to it, you may send an email to info@hitinspections.com.
              </p>

              <br/><br/>
              <p className='hit-p-terms-text primary'>
                  This document was last updated on April 30th, 2023
              </p>
              <br />
              <br />
              <br />
              <br />
              <div className="text-center">
                <img
                  src="/images/home-inspectors-logo.png"
                  alt="home-inspectors-toolbox-logo"
                  className="img-fluid"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default TermsAndConditions;
