export const reportIcons = [
  require("./four-point-icon.png"),
  require("./wind-mitigation-icon.png"),
  require("./roof-certification-icon.png"),
  require("./commercial-roof-certification-icons.png"),
  require("./wind-mitigation-type-ii-and-iii-icon.png"),
  require("./wdo-wood-destroying-organism-inspection-icon.png"),
  require("./va-wdo-icons.png"),
  require("./radon-inspection-icon.png"),
];
