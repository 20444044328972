export const homeCarouselImages = [
  require("./home-inspectors-toolbox-all-devices-preview.png"),
  require("./home-inspectors-toolbox-mobile-four-point-menu-photo.png"),
  require("./home-inspectors-toolbox-mobile-four-point.png"),
  require("./home-inspectors-toolbox-mobile-menu.png"),
  require("./home-inspectors-toolbox-mobile-roof-certification.png"),
  require("./home-inspectors-toolbox-mobile-schedule.png"),
  require("./home-inspectors-toolbox-tablet-four-point.png"),
  require("./home-inspectors-toolbox-tablet-roof-certification.png"),
  require("./home-inspectors-toolbox-tablet-schedule.png"),
  require("./home-inspectors-toolbox-tablet-wind-mitigation.png"),
  require("./home-inspectors-toolbox-web-four-point.png"),
  require("./home-inspectors-toolbox-web-roof-certification.png"),
  require("./home-inspectors-toolbox-web-wind-mitigation.png"),
];
