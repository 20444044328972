import React, { useEffect, useState } from "react";
import { Carousel, Modal } from "react-bootstrap";

import "../styles/PopupGallery.css";

const HeroGallery = ({ show, hide, images, onSlideChange, defaultSlide }) => {
  const [carouselIndex, setCarouselIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setCarouselIndex(selectedIndex);
  };

  useEffect(() => {
    onSlideChange(carouselIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carouselIndex]);

  useEffect(() => {
    if (defaultSlide === null) {
      setCarouselIndex(0);
    } else {
      setCarouselIndex(defaultSlide);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  return (
    <Modal className="hero-gallery" show={show} onHide={hide} fullscreen>
      <Modal.Body className="d-flex flex-column">
        <Modal.Header closeButton></Modal.Header>
        {carouselIndex !== null ? (
          <Carousel
            activeIndex={carouselIndex}
            onSelect={handleSelect}
            className={`overflow-hidden flex-grow-1`}
            interval={2000}
          >
            {images.map((image, index) => {
              return (
                <Carousel.Item className="h-100" key={index}>
                  <img
                    className="d-block w-100 h-100"
                    src={image}
                    alt={`Slide ${index + 1}`}
                    style={{ objectFit: "contain", objectPosition: "center" }}
                  />
                </Carousel.Item>
              );
            })}
          </Carousel>
        ) : null}
        <div className="d-flex justify-content-center mt-3">
          {images.map((image, index) => {
            return (
              <div className="image-indicators" key={index}>
                <img
                  onClick={() => handleSelect(index)}
                  className="d-block"
                  src={image}
                  alt={`Slide ${index + 1}`}
                  style={{
                    opacity: carouselIndex === index ? 1 : 0.5,
                  }}
                />
              </div>
            );
          })}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default HeroGallery;
