import "./App.css";
import Navbar from "./components/Navbar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import TermsAndConditions from "./pages/TermsAndConditions";
import AccountDeletion from "./pages/AccountDeletion";
import { NavContext } from "./contexts/navContext";
import { useState } from "react";

function App() {
  const [jump, setJump] = useState("");

  return (
    <>
      <Router>
        <Navbar onJump={(id) => setJump(id)} />
        <NavContext.Provider value={{ jump, setJump }}>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route
              exact
              path="/terms-and-conditions"
              element={<TermsAndConditions />}
            />
            <Route
              exact
              path="/delete-account"
              element={<AccountDeletion />}
            />
            <Route exact path="/*" element={<Home />} />
          </Routes>
        </NavContext.Provider>
      </Router>
    </>
  );
}

export default App;
