import gsap from "gsap";
import { CustomEase } from "gsap/all";
import ScrollTrigger from "gsap/ScrollTrigger";

export const useAnimations = ({ comp }) => {
  let ctx = () =>
    gsap.context(() => {
      gsap.registerPlugin(ScrollTrigger);
      gsap.registerPlugin(CustomEase);
      gsap.config({
        nullTargetWarn: false,
      });

      const showOnScrollElements = gsap.utils.toArray(".show-on-scroll");
      showOnScrollElements.forEach((showOnScrollElement) => {
        gsap.fromTo(
          showOnScrollElement,
          {
            y: "100%",
            opacity: 0,
          },
          {
            y: 0,
            opacity: 1,
            scrollTrigger: {
              trigger: showOnScrollElement,
              start: "top 80%",
            },
            ease: CustomEase.create(
              "custom",
              "M0,0,C0,0,0.237,-0.067,0.4,0.5,0.552,1.03,1,1,1,1"
            ),
            duration: 1,
          }
        );
      }, comp);
    });

  return { ctx };
};
