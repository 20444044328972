import React, { useEffect } from "react";

import Container from 'react-bootstrap/Container';

function AccountDeletion() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="container mt-4 p-1">
            <Container>
                <h1 className="text-center text-danger">Account Deletion</h1>

                <p>We are sad to see you go. &nbsp;😞</p>
                <p className='justify'> But to request for your account to be deleted, please send an email to <i>service@homeinspspectorstoolbox.com</i></p>
                <p className="italic">Please follow the email format below</p>
                <p>
                    <span className="bold">Subject:</span> Request to Delete Account <br/>
                    <span className="bold">Email Body:</span><br/>
                    Name: <br/>
                    Registered Email: <br/>
                    Reason: 

                </p>
               
                <hr/>

                <p>All your personal data will be deleted once we have processed your request to delete your account.</p>
                <p>Should you want to want to go back, you can easily register again using the same information.</p>

                <h3 className='text-center mt-5'>We thank you for taking interest on <b>Home Inspectors Toolbox</b>&nbsp;♥️</h3>
            </Container>
        </div>
    )
}

export default AccountDeletion