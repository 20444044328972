import React, { useContext, useEffect, useRef, useState } from "react";
import moment from "moment-timezone";
import "../App.css";
import "../styles/Home.css";
import HeroSection from "../components/HeroSection";
import PopupGallery from "../components/PopupGallery";
import HeroFeatures from "../components/HeroFeatures";
import ReportsList from "../components/ReportsList";
import { featureImages } from "../assets/images/features";

import { Link } from "react-router-dom";

import { Button } from "../components/Button";

import { Container, Row, Col, Card } from "react-bootstrap";
import { useAnimations } from "../hooks/useAnimations";
import { NavContext } from "../contexts/navContext";
import { homeCarouselImages } from "../assets/images/carousel";
import { socialIcons } from "../assets/icons";

function Home() {
  const comp = useRef();

  const { jump, setJump } = useContext(NavContext);

  const { ctx } = useAnimations({ comp });

  const [isMobile, setIsMobile] = useState(false);
  const [gallery, setGallery] = useState({
    show: false,
    images: [],
    slide: null,
  });

  const checkIsMobile = () => {
    if (window.innerWidth <= 950) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    ctx();

    return () => ctx().revert();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const scroll = (e, sectionClass) => {
    e?.preventDefault();
    const section = document.querySelector(`.${sectionClass}`);
    section.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  window.addEventListener("resize", checkIsMobile);

  useEffect(() => {
    if (jump) {
      scroll(null, jump);
      setJump("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jump]);

  return (
    <div ref={comp}>
      <PopupGallery
        show={gallery.show}
        hide={() =>
          setGallery(() => ({
            show: false,
            images: [],
            slide: null,
          }))
        }
        images={gallery.images}
        defaultSlide={gallery.slide}
        onSlideChange={(index) => {
          if (gallery.show) {
            setGallery((prevState) => ({
              ...prevState,
              slide: index,
            }));
          }
        }}
      />
      <HeroSection
        showGallery={() =>
          setGallery((prevState) => ({
            ...prevState,
            show: true,
            images: homeCarouselImages,
          }))
        }
      />
      <div className="report-list">
        <div className="container hit-container">
          <h3 className="text-white text-center">
            Introducing the ultimate solution for{" "}
            <b>
              <u>
                <i>Home Insurance Inspection</i>
              </u>
            </b>
            .
          </h3>
          <br />
          <p className="text-white text-center">
            With our innovative cloud based mobile and web application, home
            inspectors can now easily get their clients’ information, manage
            their schedule, create and send their electronically signed
            inspection reports to their clients with just a few taps and clicks.
          </p>
          <p className="text-white text-center">
            Home Inspection Toolbox is the most comprehensive solution available
            in the market, giving you everything you need for your home
            inspection business. With our cutting-edge technology and unmatched
            expertise, your home inspection just got a whole lot easier.
          </p>
          <p className="text-white text-center">
            Click-through our user-friendly, industry and Citizens-approved
            forms to enter your inspection findings and add photos, to produce
            and create up to eight different insurance reports including:
          </p>
          <br />
          <br />
          <br />
          <Container>
            <ReportsList />
          </Container>
          <br />
          <br />
          <br />
          <p className="text-white text-center">
            Send completed reports in PDF format along with an invoice to the
            client and their agent directly via email from your smart phone,
            tablet or computer with the click of a button – it’s that simple.
          </p>
          <br />
          <p className="text-white text-center">
            At no extra charge, Home Inspectors Toolbox automatically uploads
            all reports to your company’s profile where its stored on our secure
            server, so you can access it at any time.
          </p>
          <br />
          <br />
          <p className="text-white bold italic text-center">
            Stay tuned for additional inspection forms to be added.
          </p>
        </div>
      </div>

      <div className="container hit-container hit-features">
        <h2 className="text-center primary show-on-scroll">Features</h2>
        <br />
        <br />
        <br />
        <Container>
          <HeroFeatures
            carouselClick={(index) =>
              setGallery(() => ({
                slide: index,
                show: true,
                images: featureImages,
              }))
            }
            galleryIndex={gallery.slide}
          />
          <Row>
            <Col>
              <div className="text-center mt-5">
                <Button
                  className="btns center"
                  buttonStyle="btn--primary"
                  buttonSize="btn--large"
                  onClick={() =>
                    window.open(
                      "https://app.homeinspectorstoolbox.com/register",
                      "_blank"
                    )
                  }
                >
                  Get Started Now
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <hr />
      <div className="container hit-container hit-pricing">
        <h2 className="text-center primary show-on-scroll">Pricing</h2>
        <br />
        <br />
        <br />
        <Container>
          <Row>
            <Col xsm={12} md={12} lg={4}>
              <Card className="pricing-card">
                <Card.Body>
                  <Card.Title className="text-center">
                    <h2 className="hit-pricing-title">$29.49</h2>
                  </Card.Title>
                  <Card.Subtitle className="mb-2 hit-pricing-description">
                    <p className="text-small text-center">
                      Monthly Subscription <br />
                      <i>
                        Billed <b>monthly</b>
                      </i>
                    </p>
                  </Card.Subtitle>
                  <p className="text-medium">
                    <i className="fa-solid fa-check success"></i> &nbsp;Full
                    access to more than 8 inspection forms.
                  </p>
                  <p className="text-medium">
                    <i className="fa-solid fa-check success"></i>{" "}
                    &nbsp;Unlimited number of clients and inspection reports.
                  </p>
                  <p className="text-medium">
                    <i className="fa-solid fa-check success"></i>{" "}
                    &nbsp;Online client request form.
                  </p>
                  <p className="text-medium">
                    <i className="fa-solid fa-check success"></i> &nbsp;All
                    inspection reports saved to your Home Inspectors Toolbox
                    profile.
                  </p>
                  <p className="text-medium">
                    <i className="fa-solid fa-check success"></i>{" "}
                    &nbsp;Accessible 24/7 from multiple devices.
                  </p>
                  <p className="text-medium">
                    <i className="fa-solid fa-check success"></i>{" "}
                    &nbsp;Editable report with auto e-signature, date and time attached
                  </p>
                  <p className="text-medium">
                    <i className="fa-solid fa-check success"></i>{" "}
                    &nbsp;Free email communication to clients
                  </p>
                  <p className="text-medium">
                    <i className="fa-solid fa-check success"></i>{" "}
                    &nbsp;Easily copy report information from previous report
                  </p>
                  <p className="text-medium">
                    <i className="fa-solid fa-check success"></i>{" "}
                    &nbsp;Easily copy inspection report photos
                  </p>
                  <p className="text-medium">
                    <i className="fa-solid fa-check success"></i>{" "}
                    &nbsp;In-app Customer support.
                  </p>
                  <br />
                  <div className="text-center">
                    <Button
                      className="btns"
                      buttonStyle="btn--success"
                      buttonSize="btn--medium"
                      onClick={() =>
                        window.open(
                          "https://app.homeinspectorstoolbox.com/register",
                          "_blank"
                        )
                      }
                    >
                      Get Started Now
                    </Button>
                  </div>
                  <br />
                </Card.Body>
              </Card>
              <br />
            </Col>
            <Col xsm={12} md={12} lg={4}>
              <Card className="pricing-card">
                <Card.Body>
                  <Card.Title className="text-center">
                    <h2 className="hit-pricing-title">$176.99</h2>
                  </Card.Title>
                  <Card.Subtitle className="mb-2 hit-pricing-description">
                    <p className="text-small text-center">
                      Semi-annual Subscription <br />
                      <i>
                        Billed every <b>6 months</b>
                      </i>
                    </p>
                  </Card.Subtitle>
                  <p className="text-medium">
                    <i className="fa-solid fa-check success"></i> &nbsp;Full
                    access to more than 8 inspection forms.
                  </p>
                  <p className="text-medium">
                    <i className="fa-solid fa-check success"></i>{" "}
                    &nbsp;Unlimited number of clients and inspection reports.
                  </p>
                  <p className="text-medium">
                    <i className="fa-solid fa-check success"></i>{" "}
                    &nbsp;Online client request form.
                  </p>
                  <p className="text-medium">
                    <i className="fa-solid fa-check success"></i> &nbsp;All
                    inspection reports saved to your Home Inspectors Toolbox
                    profile.
                  </p>
                  <p className="text-medium">
                    <i className="fa-solid fa-check success"></i>{" "}
                    &nbsp;Accessible 24/7 from multiple devices.
                  </p>
                  <p className="text-medium">
                    <i className="fa-solid fa-check success"></i>{" "}
                    &nbsp;Editable report with auto e-signature, date and time attached
                  </p>
                  <p className="text-medium">
                    <i className="fa-solid fa-check success"></i>{" "}
                    &nbsp;Free email communication to clients
                  </p>
                  <p className="text-medium">
                    <i className="fa-solid fa-check success"></i>{" "}
                    &nbsp;Easily copy report information from previous report
                  </p>
                  <p className="text-medium">
                    <i className="fa-solid fa-check success"></i>{" "}
                    &nbsp;Easily copy inspection report photos
                  </p>
                  <p className="text-medium">
                    <i className="fa-solid fa-check success"></i>{" "}
                    &nbsp;In-app Customer support.
                  </p>
                  <br />
                  <div className="text-center">
                    <Button
                      className="btns"
                      buttonStyle="btn--success"
                      buttonSize="btn--medium"
                      onClick={() =>
                        window.open(
                          "https://app.homeinspectorstoolbox.com/register",
                          "_blank"
                        )
                      }
                    >
                      Get Started Now
                    </Button>
                  </div>
                  <br />
                </Card.Body>
              </Card>
              <br />
            </Col>
            <Col xsm={12} md={12} lg={4}>
              <Card className="pricing-card">
                <Card.Body>
                  <Card.Title className="text-center">
                    <h2 className="hit-pricing-title">$350</h2>
                  </Card.Title>
                  <Card.Subtitle className="mb-2 hit-pricing-description">
                    <p className="text-small text-center">
                      Annual Subscription <br />
                      <i>
                        Billed <b>yearly</b>
                      </i>
                    </p>
                  </Card.Subtitle>
                  <p className="text-medium">
                    <i className="fa-solid fa-check success"></i> &nbsp;Full
                    access to more than 8 inspection forms.
                  </p>
                  <p className="text-medium">
                    <i className="fa-solid fa-check success"></i>{" "}
                    &nbsp;Unlimited number of clients and inspection reports.
                  </p>
                  <p className="text-medium">
                    <i className="fa-solid fa-check success"></i>{" "}
                    &nbsp;Online client request form.
                  </p>
                  <p className="text-medium">
                    <i className="fa-solid fa-check success"></i> &nbsp;All
                    inspection reports saved to your Home Inspectors Toolbox
                    profile.
                  </p>
                  <p className="text-medium">
                    <i className="fa-solid fa-check success"></i>{" "}
                    &nbsp;Accessible 24/7 from multiple devices.
                  </p>
                  <p className="text-medium">
                    <i className="fa-solid fa-check success"></i>{" "}
                    &nbsp;Editable report with auto e-signature, date and time attached
                  </p>
                  <p className="text-medium">
                    <i className="fa-solid fa-check success"></i>{" "}
                    &nbsp;Free email communication to clients
                  </p>
                  <p className="text-medium">
                    <i className="fa-solid fa-check success"></i>{" "}
                    &nbsp;Easily copy report information from previous report
                  </p>
                  <p className="text-medium">
                    <i className="fa-solid fa-check success"></i>{" "}
                    &nbsp;Easily copy inspection report photos
                  </p>
                  <p className="text-medium">
                    <i className="fa-solid fa-check success"></i>{" "}
                    &nbsp;In-app Customer support.
                  </p>
                  <br />
                  <div className="text-center">
                    <Button
                      className="btns"
                      buttonStyle="btn--success"
                      buttonSize="btn--medium"
                      onClick={() =>
                        window.open(
                          "https://app.homeinspectorstoolbox.com/register",
                          "_blank"
                        )
                      }
                    >
                      Get Started Now
                    </Button>
                  </div>
                  <br />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="hit-container-background">
        <div className="container hit-container">
          <Container>
            <Row>
              <Col sm={12} lg={8}>
                <h2 className="footer-home-inspectors-toolbox">
                  Home Inspectors Toolbox
                </h2>
                <p className="text-white text-medium">
                  Designed by home inspectors for home inspectors, Home
                  Inspectors Toolbox helps solve your home inspection reporting,
                  invoicing and customer management needs.
                </p>
                <br />
                <h4 className="text-white">Inspections</h4>
                <p className="text-white text-small">
                  Four Point Inspection, Wind Mitigation Inspection, Wind
                  Mitigation II & III Inspection, Roof Certification Inspection,
                  Commercial Certification Inspection, Wood Destroying Organisms
                  Inspection, Veterans Administration Wood Destroying Organisms
                  Inspection, Radon Inspection
                </p>
                {!isMobile ? (
                  <>
                    <br />
                    <p className="text-white italic text-small">
                      © Home Inspectors Toolbox{" "}
                      {moment(new Date()).tz("America/New_York").format("YYYY")}
                    </p>
                  </>
                ) : null}
              </Col>
              <Col sm={12} lg={4} className={isMobile ? "text-center" : ""}>
                {isMobile ? (
                  <>
                    <br />
                    <br />
                  </>
                ) : (
                  ""
                )}
                <Row>
                  <Col sm={12}>
                    <Link
                      to="#hit-pricing"
                      onClick={(e) => scroll(e, "hit-pricing")}
                      className="hit-link"
                    >
                      <h5 className="text-white">Pricing</h5>
                    </Link>
                    <br />
                    <Link
                      to="#hit-features"
                      onClick={(e) => scroll(e, "hit-features")}
                      className="hit-link"
                    >
                      <h5 className="text-white">Features</h5>
                    </Link>
                    <br />
                    <Link to="/terms-and-conditions" className="hit-link">
                      <h5 className="text-white">Terms and Conditions</h5>
                    </Link>
                    <br />

                    <Button
                      className="btns"
                      buttonStyle="btn--secondary"
                      buttonSize="btn--medium"
                      onClick={() =>
                        window.open(
                          "https://app.homeinspectorstoolbox.com/register",
                          "_blank"
                        )
                      }
                    >
                      Sign Up Now
                    </Button>
                    <br />
                    <br />
                  </Col>

                  <Col sm={12}>
                    <br />
                    <h4 className="text-white">Socials</h4>
                    <div className="d-flex justify-content-center justify-content-md-start mt-4">
                      <Link
                        to="https://www.facebook.com/homeinspectorstoolbox.fl"
                        target="_blank"
                        className="hit-link--social me-4"
                      >
                        <img
                          className="w-100"
                          src={socialIcons.facebook}
                          alt=""
                        />
                      </Link>
                      <Link
                        to="https://www.instagram.com/homeinspectorstoolbox/
                        "
                        target="_blank"
                        className="hit-link--social me-4"
                      >
                        <img
                          className="w-100"
                          src={socialIcons.instagram}
                          alt=""
                        />
                      </Link>
                      <Link
                        to="https://www.youtube.com/@homeinspectorstoolbox"
                        target="_blank"
                        className="hit-link--social me-4"
                      >
                        <img
                          className="w-100"
                          src={socialIcons.youtube}
                          alt=""
                        />
                      </Link>
                      <Link
                        to="https://www.linkedin.com/company/home-inspectors-toolbox"
                        target="_blank"
                        className="hit-link--social"
                      >
                        <img
                          className="w-100"
                          src={socialIcons.linkedin}
                          alt=""
                        />
                      </Link>
                    </div>
                    <br />
                    <br />
                  </Col>
                </Row>

                {isMobile ? (
                  <p className="text-white italic text-small">
                    © Home Inspectors Toolbox{" "}
                    {moment(new Date()).tz("America/New_York").format("YYYY")}
                  </p>
                ) : null}
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
}

export default Home;
