import { featureImages, featureIcons } from "../assets/images/features";

export const features = [
  {
    name: "8 Inspection Forms",
    description:
      "All inspection forms created easily and accessible on both our Web and Mobile applications.",
    image: featureImages[0],
    icon: featureIcons[0],
  },
  {
    name: "Quick and easy",
    description:
      "Easily create and complete your inspection reports with a few taps and clicks.",
    image: featureImages[1],
    icon: featureIcons[1],
  },
  {
    name: "Multiple inspectors",
    description:
      "Add up to 5 inspector accounts under your company for a lower rate per month.",
    image: featureImages[2],
    icon: featureIcons[2],
  },
  {
    name: "Automatic report generation",
    description:
      "Create and send inspection report to clients automatically at the end of every inspection.",
    image: featureImages[3],
    icon: featureIcons[3],
  },
  {
    name: "Automatic invoice generation",
    description:
      "Automatically generate and send client invoice along with completed report electronically – on-site and with ease.",
    image: featureImages[4],
    icon: featureIcons[4],
  },
  {
    name: "Account synchronization",
    description: "Reports sent simultaneously to you and your client, and access all your report and client information on mutliple platforms.",
    image: featureImages[5],
    icon: featureIcons[5],
  },
  {
    name: "Saved, searchable records",
    description:
      "All client and reports information stored safely in the cloud for an easy and convinient access.",
    image: featureImages[6],
    icon: featureIcons[6],
  },
  {
    name: "Safe and secure",
    description:
      "All records are securely kept and are not shared with any third party application.",
    image: featureImages[7],
    icon: featureIcons[7],
  },
];
