import { Col, Row } from "react-bootstrap";
import { reports } from "../data/reports";

const ReportsList = () => {
  return (
    <div className="reports-list">
      <Row>
        {reports.map((report, index) => {
          return (
            <Col xsm={12} md={6} lg={4} key={index}>
              <img
                src={report.icon}
                alt="house-white"
                className="img-fluid center show-on-scroll"
              />
              <h4 className="report-list-title">{report.name}</h4>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default ReportsList;
